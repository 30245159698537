import { mapMutations, mapActions } from 'vuex';
import get from 'lodash/get';
import { SET_PROFILE } from '~/scripts/utils/constants/mutations/index';
import loginGql from '~/apollo/mutations/login.gql';
import profileGql from '~/gql/queries/profile.gql';
import AuthPage from '~/components/pages/auth/index';

const allowedRoles = ['SystemAdministrator', 'AccountManager', 'Marketer', 'WidgetMarketer'];

export default {
    components: {
        AuthPage,
    },
    data () {
        return {
            loading: false,
        };
    },
    methods: {
        ...mapActions(['showAlert']),
        ...mapMutations([SET_PROFILE]),

        // TODO: set cookie expiration date for x months upon login when shouldKeepLogin is true
        // TODO: show error when user and/or password is incorrect
        hasValidRole (role) {
            return allowedRoles.includes(role);
        },
        login () {
            return this.$apollo.mutate({
                mutation: loginGql,
                variables: this.credentials,
            });
        },
        onLogin (accessToken) {
            return this.$apolloHelpers.onLogin(accessToken);
        },
        getProfile () {
            return this.$apollo.query({
                query: profileGql,
            });

            // return get(profile, 'data.profile.result');
        },
        async setProfile (role) {
            const getProfile = await this.getProfile();
            const profile = get(getProfile, 'data.profile.result');

            const isSampler = /sampler/gi.test(role);
            const isSA = /SystemAdministrator/gi.test(role);
            const isACM = /AccountManager/gi.test(role);

            this.SET_PROFILE({
                ...profile,
                isSampler,
                isSA,
                isACM,
            });
            return profile;
        },
        redirect (role) {
            this.$router.push(`/${role.toLowerCase()}`);
        },
    },
};
