<template>
    <div class="login-page" :style="$bgImage('login/login-bg.svg')">
        <div class="form-wrapper">
            <div class="contact-us-wrapper">
                <p class="contact-us d-flex align-items-center">
                    <strong class="pr-3 account">{{ $t('page.signIn.contactUs.text') }}</strong>
                    <a class="text-uppercase btn-contact-us btn-outline-danger btn-lg" href="https://peekage.com/contact">
                        {{ $t('page.signIn.contactUs.button') }}
                    </a>
                </p>
            </div>
            <div class="logo-wrapper">
                <img :src="$image('logos/peekage.png')">
            </div>
            <div class="form-container">
                <h4>{{ $t('appName') }}</h4>
                <h2>{{ $t('page.login.businessPanel') }}</h2>
                <form class="login-form pt-3"
                      id="signIn-form"
                      @submit.prevent="login"
                      @keyup.enter="login"
                >
                    <div class="mb-4">
                        <input-text
                            :placeholder="'username@domain.com'"
                            :label="$t('label.emailAddress')"
                            id="loginEmail"
                            :required="true"
                            v-validate="'required|email'"
                            :error="errors.first('email')"
                            v-model="credentials.input.username"
                            name="email"
                            :autofocus="true"
                            input-classes="username"
                            data-test-id="input-email"
                        />
                    </div>
                    <div>
                        <input-text
                            :placeholder="$t('placeholders.password')"
                            :label="$t('label.password')"
                            id="loginPassword"
                            :required="true"
                            :input-type="'password'"
                            v-validate="'required|min:6|max:30'"
                            :error="errors.first('password')"
                            v-model="credentials.input.password"
                            name="password"
                            input-classes="password"
                            data-test-id="input-password"
                        />
                    </div>
                </form>

                <div class="forget-password my-4">
                    <p>
                        <span class="forget-password--description">{{ $t('page.login.description') }}</span>
                        <br>
                        <nuxt-link :to="'/password/forget'">
                            <span class="text black-light">{{ $t('page.login.passwordForgot') }}</span>
                            <span class="forget-password_link">{{ $t('page.login.password') }}?</span>
                        </nuxt-link>
                    </p>
                </div>

                <input-checkbox
                    :label="$t('page.login.keepMeLogin')"
                    class-name="ech-checkbox-site my-3"
                    name="should-keep-login"
                    v-model="shouldKeepLogin"
                />
                <ech-button @click="submit"
                            data-test-id="signin-button"
                            class="btn-danger text-uppercase"
                            :text="$t('page.login.signIn')"
                            icon="icon plus"
                            :loading="loading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import mixin from '~/components/pages/auth/auth';
import { showErrors } from '~/scripts/utils/index';

export default {
    mixins: [mixin],
    layout: 'blank',
    data () {
        return {
            error: null,
            credentials: {
                input: {
                    username: '',
                    password: '',
                },
            },
            shouldKeepLogin: false,
        };
    },
    fetch ({ redirect, app }) {
        const hasToken = !!app.$apolloHelpers.getToken();
        if (hasToken) redirect('/');
    },
    head () {
        return {
            title: 'Peekage: Login',
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: 'noindex',
                },
            ],
        };
    },
    mounted () {
        this.$storage.removeUniversal('form.create.campaign', '', true);
        this.$storage.removeUniversal('page.create.campaign.steps', '', true);
        this.$storage.removeUniversal('page.create.campaign.current', '');
    },
    methods: {
        async submit () {
            const valid = await this.$validator.validateAll();
            if (valid) {
                try {
                    this.loading = true;
                    const result = await this.login();
                    const roles = get(result, 'data.authViaPassword.result.roles');

                    if (!this.hasValidRole(roles)) throw new Error();

                    await this.onLogin(result.data.authViaPassword.result.accessToken);

                    const profile = await this.setProfile(roles);

                    const { id, emailVerified, email } = profile;

                    if (roles === 'WidgetMarketer' && !emailVerified) {
                        await this.$apolloHelpers.onLogout();
                        return this.$router.push({
                            query: {
                                id,
                                type: 'email',
                                username: email,
                            },
                            path: '/verify',
                        });
                    }

                    this.redirect(roles);
                } catch (error) {
                    showErrors(error, this);
                } finally {
                    this.loading = false;
                }
            }
        },
    },
};
</script>
