<template>
    <div class="login-page" :style="$bgImage('login/login-bg.svg')">
        <div class="form-wrapper">
            <div class="contact-us-wrapper">
                <p class="contact-us d-flex align-items-center">
                    <strong class="pr-3 account">{{ $t('page.signIn.contactUs.text') }}</strong>
                    <a class="text-uppercase btn-contact-us btn-outline-danger btn-lg" href="https://peekage.com/contact">
                        {{ $t('page.signIn.contactUs.button') }}
                    </a>
                </p>
            </div>
            <div class="logo-wrapper">
                <img :src="$image('logos/peekage.png')">
            </div>
            <slot name="container" />
        </div>
    </div>
</template>

<script>
export default {
    layout: 'blank',
};
</script>
