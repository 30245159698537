// add any global utils here
import get from 'lodash/get';

export function textSplitter (value, splitter) {
    return value.split(splitter);
}

export function textJoiner (value, separator) {
    value[0] = `20${value[0]}`;
    return value.join(separator);
}

export function dateFormatter (value) {
    const correctFormat = textJoiner(textSplitter(value, '/').reverse(), '-');
    return dateISOString(correctFormat);
}

export function dateISOString (value) {
    return new Date(value).toISOString();
}

export async function asyncForEach (array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

export function removeEmptyKeys (obj) {
    const newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop] !== '' || obj[prop] != null || !Object.keys(obj[prop]).length) { newObj[prop] = obj[prop]; }
    });
    return newObj;
}

export function removeMeta (obj, key) {
    for (const prop in obj) {
        if (prop === key) delete obj[prop];
        else if (typeof obj[prop] === 'object') removeMeta(obj[prop], key);
    }
}

export function showErrors (err, instance) {
    if (err.graphQLErrors && err.graphQLErrors.length) {
        err.graphQLErrors.forEach((error) => {
            instance.showAlert({
                text: error.message,
                type: 'warning',
            });
        });
    } else {
        const errMessage = get(err, 'message', instance.$t('errors.server'));
        instance.showAlert(errMessage);
    }
}
